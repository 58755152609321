import React from "react";
import { NavLink } from "react-router-dom";

const NavBar = () => {
  return (
    <nav className="navbar fixed-top navbar-expand-lg navbar-dark bg-grey nav-shadow">
      <NavLink className="navbar-brand" to="/">
        <strong>Muji</strong> Consulting
      </NavLink>
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarNavAltMarkup"
        aria-controls="navbarNavAltMarkup"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon" />
      </button>
      <div
        className="collapse navbar-collapse justify-content-end"
        id="navbarNavAltMarkup"
      >
        <div className="navbar-nav">
          <NavLink className="nav-item nav-link active" to="/">
            Home <span className="sr-only">(current)</span>
          </NavLink>
        </div>
        <div className="navbar-nav">
          <a className="nav-item nav-link" href={"mailto:flashmuji@gmail.com"}>
            Contact
          </a>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
