import React from "react";
import HeaderSection from "./headerSection";
import SkillsSection from "./skillsSection";
import MyWorkSection from "./myWorkSection";
import CollaborationSection from "./collaborationSection";
import TestimonialSection from "./testimonialSection";
import FooterSection from "./footerSection";
import BioSection from "./bioSection";

const Home = () => {
  return (
    <div>
      <HeaderSection />
      <BioSection />
      <SkillsSection />
      <MyWorkSection />
      <CollaborationSection />
      <TestimonialSection />
      <FooterSection />
    </div>
  );
};

export default Home;
