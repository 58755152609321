import React from "react";
import SnapChatThumb from "../images/snapchat.jpg";
import USVolatilityThumb from "../images/us-vol.jpg";
import DubaiThumb from "../images/dubai-thumb.jpg";
import SwitchersThumb from "../images/switchers-thumb.jpg";
import SochiThumb from "../images/sochi-thumb.jpg";
import BannerToolThumb from "../images/bannertool-thumb.jpg";
import IControlThumb from "../images/icontrol-thumb.jpg";
import QuantasThumb from "../images/quantas-thumb.jpg";
import IGNo1Thumb from "../images/ig-no1-thumb.jpg";
import IGUSLaunch from "../images/us-launch-thumb.jpg";
import SocialIcon from "../images/social-thumb.jpg";

import { Link } from "react-router-dom";

const MyWorkSection = () => {
  return (
    <section id="mywork">
      <h2>My Recent Work</h2>
      <p>Here are a few recent projects. Want to see more? Email me.</p>
      <div className="container">
        <div className="row">
          <div className="col-md-4 mt-2 fadeAnim">
            <Link to="/project/Hkm9mTTUHTs0Mzzw1idQ">
              <img
                src={IControlThumb}
                className="rounded img-fluid"
                alt="iControl"
              />
            </Link>
          </div>
          <div className="col-md-4 mt-2 fadeAnim">
            <Link to="/project/tKtg5lsnzJuJnt2MU1ft">
              <img
                src={BannerToolThumb}
                className="rounded img-fluid"
                alt="Banner Tool Project"
              />
            </Link>
          </div>
          <div className="col-md-4 mt-2 fadeAnim">
            <Link to="/project/osV0xPv8L90rUl8WG6fk">
              <img
                src={SocialIcon}
                className="rounded img-fluid"
                alt="Social Tool Project"
              />
            </Link>
          </div>
          <div className="col-md-4 mt-2 fadeAnim">
            <Link to="/project/GkfezNYar8O4cN2srW1Q">
              <img
                src={IGUSLaunch}
                className="rounded img-fluid"
                alt="IG US Launch Campaign"
              />
            </Link>
          </div>
          <div className="col-md-4 mt-2 fadeAnim">
            <Link to="/project/I2Ubm2YX3wDIjiZJCYJY">
              <img
                src={QuantasThumb}
                className="rounded img-fluid"
                alt="Quantas Co-Branded Campaign"
              />
            </Link>
          </div>
          <div className="col-md-4 mt-2 fadeAnim">
            <Link to="/project/Ha71UlO91zOCTB05XIIe">
              <img
                src={IGNo1Thumb}
                className="rounded img-fluid"
                alt="IG No1 Provider"
              />
            </Link>
          </div>
          <div className="col-md-4 mt-2 fadeAnim">
            <Link to="/project/1uiwRTfqovXTIW9PJKF1">
              <img
                src={SnapChatThumb}
                className="rounded img-fluid"
                alt="SnapChat IPO"
              />
            </Link>
          </div>

          <div className="col-md-4 mt-2 fadeAnim">
            <Link to="/project/G7kzDTwUCVCllRca4XIf">
              <img
                src={USVolatilityThumb}
                className="rounded img-fluid"
                alt="US Volatility"
              />
            </Link>
          </div>
          <div className="col-md-4 mt-2 fadeAnim">
            <Link to="/project/Je38dsX0K2Bule2IKKQx">
              <img
                src={DubaiThumb}
                className="rounded img-fluid"
                alt="Dubai Launch"
              />
            </Link>
          </div>
          <div className="col-md-4 mt-2 fadeAnim">
            <Link to="/project/lchXZjYaQvEJIR88s9JP">
              <img
                src={SwitchersThumb}
                className="rounded img-fluid"
                alt="Switcher Campaign"
              />
            </Link>
          </div>

          <div className="col-md-4 mt-2 fadeAnim">
            <Link to="/project/1glGfHbqWOzzkFjdUBib">
              <img
                src={SochiThumb}
                className="rounded img-fluid"
                alt="Sochi Winter Olympics"
              />
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MyWorkSection;
