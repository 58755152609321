import React from "react";
import Skill from "./skill";
import FrontEndIcon from "../images/frontend.svg";
import SkillsIcon from "../images/skills.svg";
import Fade from "react-reveal/Fade";
import TrackVisibility from "react-on-screen";

const SkillsSection = () => {
  return (
    <section id="skills-section" className="mx-auto">
      <div className="container">
        <div className="row">
          <Fade>
            <div className="col-sm mb-5">
              <img src={FrontEndIcon} alt="Front-end icon" />
              <h3>Front-end Developer</h3>
              <p>
                I like to code things from scratch, and enjoy bringing ideas to
                life in the browser.
              </p>
              <p id="subheading">Languages I speak:</p>
              <p>HTML, JavaScript, CSS, Sass</p>
              <p id="subheading">Dev Tools:</p>
              <ul>
                <li>Visual Studio</li>
                <li>Atom</li>
                <li>NPM</li>
                <li>Firebase</li>
                <li>Codepen</li>
                <li>Github</li>
                <li>Terminal</li>
              </ul>
            </div>
          </Fade>

          <div className="col-sm">
            <img src={SkillsIcon} alt="Skills icon" />
            <h3>Coding Skills/Frameworks</h3>
            <TrackVisibility once>
              <Skill name="HTML" percent="98%" />
              <Skill name="JavaScript" percent="90%" />
              <Skill name="GreenSock" percent="87%" />
              <Skill name="Bootstap" percent="82%" />
              <Skill name="React" percent="80%" />
              <Skill name="Angular" percent="50%" />
              <Skill name="Vue" percent="50%" />
              <Skill name="Firestore" percent="40%" />
              <Skill name="Node.js" percent="15%" />
            </TrackVisibility>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SkillsSection;
