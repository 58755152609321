import React from "react";
import Richard from "../images/richard-cummings.jpeg";
import Horacio from "../images/horacio.jpeg";
import Jesper from "../images/jesper.jpeg";
import Fade from "react-reveal/Fade";

const TestimonialSection = () => {
  return (
    <section id="testimonials">
      <Fade>
        <h1>Testimonials</h1>
        <p>People I've worked with have said some nice things...</p>
        <div
          id="carouselExampleIndicators"
          className="carousel slide mx-auto"
          data-ride="carousel"
        >
          <ol className="carousel-indicators">
            <li
              data-target="#carouselExampleIndicators"
              data-slide-to="0"
              className="active"
            />
            <li data-target="#carouselExampleIndicators" data-slide-to="1" />
            <li data-target="#carouselExampleIndicators" data-slide-to="2" />
          </ol>
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img
                src={Richard}
                alt="Richard Cummings, Google"
                className="imageCircle testimonials"
              />
              <p>
                "Amit has been a pleasure to work with and a true professional
                over the 3.5 years that we have worked in a Tech platform/client
                relationship. Amit is a power user of the Google Marketing
                Platform product with deep expertise in Creative development and
                Data Driven Strategies. Amit regularly provides constrictive
                product feedback and is always keen to push for technological
                advancements."
              </p>
              <h4>Richard Cumming</h4>
              <p>Advertising Solution Consultant, Google</p>
            </div>
            <div className="carousel-item">
              <img
                src={Horacio}
                alt="Horacio Lopez"
                className="imageCircle testimonials"
              />
              <p>
                "I've worked with Amit on large enterprise software. He is a
                creative tech, the combination of skilled developer and creative
                flair with an eye and passion for detail. Amit brings years of
                experience as front end developer allowing him to quickly adapt
                to new development processes and frameworks. His team oriented
                approach assures he is able to contribute in more ways than
                one."
              </p>
              <h4>Horacio Lopez</h4>
              <p>Front-end Developer, RBS</p>
            </div>
            <div className="carousel-item">
              <img
                src={Jesper}
                alt="Jesper"
                className="imageCircle testimonials"
              />
              <p>
                "Amit is one those rare developers that can easily explain the
                process and coding language in a such a way so that his none
                techy colleagues can easily understand. Needless to say he’s
                also a top developer and work was always delivered on time and
                to spec when we worked together. Amit is also a caring person
                and all this combined makes him a great guy to work with."
              </p>
              <h4>Jesper Löfgren</h4>
              <p>Client Director, BERNTZON:BYLUND</p>
            </div>
          </div>
        </div>
      </Fade>
    </section>
  );
};

export default TestimonialSection;
