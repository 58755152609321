import React from "react";
import AbobeIcon from "../images/adobe.png";
import DisneyIcon from "../images/disney.png";
import MaclarenIcon from "../images/maclaren.png";
import MicrosoftIcon from "../images/microsoft.png";
import PampersIcon from "../images/pampers.png";
import VodafoneIcon from "../images/vodafone.png";
import IGIcon from "../images/ig.png";
import FTIcon from "../images/ft.png";
import Fade from "react-reveal/Fade";

const CollaborationSection = () => {
  return (
    <section id="collaboration">
      <h1 className="mx-auto">
        I'm proud to have worked with some awesome companies:
      </h1>
      <div className="container mx-auto">
        <div className="row">
          <div className="col-sm-6 col-md-4 col-lg-3 mt-3">
            <Fade>
              <img src={AbobeIcon} alt="Adobe" />
            </Fade>
          </div>
          <div className="col-sm-6 col-md-4 col-lg-3 mt-3">
            <Fade>
              <img src={DisneyIcon} alt="Disney" />
            </Fade>
          </div>
          <div className="col-sm-6 col-md-4 col-lg-3 mt-3">
            <Fade>
              <img src={MaclarenIcon} alt="Maclaren F1" />
            </Fade>
          </div>
          <div className="col-sm-6 col-md-4 col-lg-3 mt-3">
            <Fade>
              <img src={MicrosoftIcon} alt="Microsoft" />
            </Fade>
          </div>
          <div className="col-sm-6 col-md-4 col-lg-3 mt-3">
            <Fade>
              <img src={PampersIcon} alt="Pampers" />
            </Fade>
          </div>
          <div className="col-sm-6 col-md-4 col-lg-3 mt-3">
            <Fade>
              <img src={VodafoneIcon} alt="Vodafone" />
            </Fade>
          </div>
          <div className="col-sm-6 col-md-4 col-lg-3 mt-3">
            <Fade>
              <img src={IGIcon} alt="IG.com" />
            </Fade>
          </div>
          <div className="col-sm-6 col-md-4 col-lg-3 mt-3">
            <Fade>
              <img src={FTIcon} alt="Financial Time" />
            </Fade>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CollaborationSection;
