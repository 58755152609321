import * as firebase from "firebase";

let config = {
  apiKey: "AIzaSyDtVzINMDBIkCM4QWsXveLt-Z-dIdhco0s",
  authDomain: "muji-consulting.firebaseapp.com",
  databaseURL: "https://muji-consulting.firebaseio.com",
  projectId: "muji-consulting",
  storageBucket: "muji-consulting.appspot.com",
  messagingSenderId: "258283397230"
};

firebase.initializeApp(config);

export default firebase;
