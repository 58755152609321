import React from "react";
import Fade from "react-reveal/Fade";

const BioSection = () => {
  return (
    <section id="bio">
      <div className="mx-auto">
        <Fade>
          <h1>Hi, I’m Amit. Nice to meet you.</h1>
        </Fade>
        <Fade>
          <h2>
            Since beginning my journey as a Front-end developer over 14 years
            ago, I've done work for agencies, worked for some big clients,
            managed teams both locally and in remote locations, consulted for
            startups, and collaborated with talented people to create digital
            online journeys from websites/apps to online advertising. I'm
            quietly confident, naturally curious, and always working on
            improving, learning new skills one framework at a time.
          </h2>
        </Fade>
      </div>
    </section>
  );
};

export default BioSection;
