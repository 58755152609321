import React from "react";
import Fade from "react-reveal/Fade";
import LinkedInIcon from "../images/linkedin.svg";

const FooterSection = () => {
  return (
    <section id="footer" className="footer">
      <div className="container box">
        <div className="row pt-3">
          <div className="col-sm mb-3">
            <Fade>
              <h1>Start a project</h1>
            </Fade>
          </div>
          <Fade>
            <div className="col-sm mb-3">
              Interested in working together? We should queue up a chat. I’ll
              buy the coffee.
            </div>
          </Fade>
          <div className="col-sm mb-3">
            <Fade>
              <a
                className="btn btn-outline-primary-footer btn-lg"
                href={"mailto:flashmuji@gmail.com"}
              >
                Let's do this
              </a>
            </Fade>
          </div>
        </div>
      </div>
      <div className="mt-5">
        <div>
          <h5>Link me on LinkedIn </h5>
          <a href="https://www.linkedin.com/in/amitkapadia/" target="blank">
            <img className="linkedin" src={LinkedInIcon} alt="LinkedIn" />
          </a>
          <p className="mt-5">
            This site was hand coded using <strong>React!</strong>
          </p>
        </div>
      </div>
    </section>
  );
};

export default FooterSection;
