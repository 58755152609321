import React from "react";
import Avatar from "../images/avatar-check-shirt.svg";
import HeroDiagram from "../images/hero.png";
import Fade from "react-reveal/Fade";
//https://www.react-reveal.com/docs/

const HeaderSection = () => {
  return (
    <section id="header">
      <Fade>
        <h1 className="mx-auto">Muji Consulting: Front-end Developer</h1>
      </Fade>
      <Fade>
        <h2 className="mx-auto">
          I code Front-end apps, and I love what I do.
        </h2>
      </Fade>
      <Fade>
        <img
          src={Avatar}
          style={{ marginTop: 40, marginBottom: 40 }}
          alt="Amit avatar"
          className="avatar"
        />
      </Fade>
      <Fade>
        <img src={HeroDiagram} width="80%" alt="Development diagram" />
      </Fade>
    </section>
  );
};

export default HeaderSection;
