import React from "react";
import "./skill.css";

const Skill = props => {
  const { name, percent, isVisible } = props;

  let fillWidth = {
    width: "0%"
  };

  if (isVisible) {
    fillWidth.width = percent;
  }

  return (
    <div id="skills" className="barBG">
      <div style={fillWidth} className="barFill">
        {name}
      </div>
    </div>
  );
};

export default Skill;
