import React from "react";
//import "./App.css";
import "./App.scss";
//import NavBar from "./components/navBar";
import { Route, Switch } from "react-router-dom";
import ProjectComp from "./components/projectComp";
import Home from "./components/home";
import NavBar from "./components/navBar";

function App() {
  return (
    <div className="App">
      <NavBar />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/project/:project_id" component={ProjectComp} />
      </Switch>
    </div>
  );
}

export default App;
