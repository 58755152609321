import React, { PureComponent } from "react";
//import firebase from "firebase/app";
//import "firebase/firestore";
//import { init } from "../config/firebaseDB";
import firebase from "../config/firebase.js";
import FooterSection from "./footerSection";

class ProjectComp extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      id: null,
      project: {},
      code: [],
      el: <p> </p>
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const id = this.props.match.params.project_id;
    this.setState({ id });
    //init();

    const db = firebase.firestore();
    const collectionRef = db.collection("projects");

    collectionRef
      .doc(id)
      .get()
      .then(doc => {
        console.log("query ", doc.data());
        //this.boo = doc.data();
        this.setState({ project: doc.data() });
        this.setState({ code: doc.data().code });
        const code = Object.values(this.state.code);
        console.log("yoyo", code);

        const tags = code.map(c => (
          <span className="badge badge-primary mr-1" key={c}>
            {c}
          </span>
        ));
        this.setState({ el: tags });
      });
  }
  render() {
    const styling = {
      marginTop: 80,
      width: "85%"
    };

    const spacing = {
      marginBottom: 160
    };

    let videoEl = <div> </div>;
    const hasVideo = this.state.project.video;

    if (hasVideo) {
      videoEl = (
        <div
          className="video-container"
          dangerouslySetInnerHTML={{ __html: this.state.project.video }}
        />
      );
    }

    const hasIframe = this.state.project.iframe;
    let iframeEl = <div> </div>;

    if (hasIframe) {
      iframeEl = (
        <iframe
          title="banner"
          src={process.env.PUBLIC_URL + this.state.project.iframe}
          width="300"
          height="250"
          frameBorder="0"
          scrolling="no"
        />
      );
    }

    return (
      <div style={styling} className="card mx-auto">
        <div className="card-header">Company: {this.state.project.company}</div>

        <div style={spacing}>
          <div className="card-body">
            <h2 className="card-title">
              Project: {this.state.project.projectTitle}
            </h2>
            <p className="card-text">{this.state.project.projectDescription}</p>
            <div className="card-text mb-3">What I used: {this.state.el}</div>
            <div>{videoEl}</div>
            <div>{iframeEl}</div>
          </div>
          <img
            className="card-img-top"
            src={this.state.project.thumb}
            alt="Project thumb"
          />
        </div>
        <FooterSection />
      </div>
    );
  }
}

export default ProjectComp;
